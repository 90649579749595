import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import { currentAge } from '../../lib/calculations'
import CountryWithFlag from '../common/CountryWithFlag'
import Slider from 'react-slick'
import Loading from '../common/Loading'
import ShortListButton from '../common/ShortListButton'
import { fetchCountry } from '../../lib/resolver'
import moment from 'moment'
import useAuth from '../../hooks/useAuth'
import { useControls } from '../../hooks/useHooks'

export default ({ loading = true, data: { profile = [] } }) => {
    const [isComponentLoaded, setIsComponentLoaded] = useState(false);
    
    const { isAuthenticated } = useAuth();
    const { showLoginModalWindow } = useControls();
    
    useEffect(() => {
        setIsComponentLoaded(true);
    }, [])

    if (loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1292,
                settings: {
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    }
    
    const slide = profiles => {
        return profiles.map((profileData, index) => {
            const {
                id,
                reference,
                profile_photo,
                avatar,
                created_at,
                basic_information: {
                    profile_created_by,
                    living_city,
                    living_country,
                    dob,
                    religion,
                    mother_tongue,
                },
                lifestyle_and_appearance: { height },
            } = profileData

            return (
                <div data-index={index} key={reference} className="freelancer slick-slide">
                    <div className="freelancer-overview">
                        <div className="freelancer-overview-inner">
                            <ShortListButton
                                profile={profileData}
                                isLargeButton={false}
                                profile={profileData}
                            />
                            <div
                                className="freelancer-avatar"
                                style={{ display: 'inline-block' }}
                            >
                                <img src={profile_photo !== 'null' ? profile_photo : avatar} />
                            </div>

                            <div className="freelancer-name">
                                <div className="verified-container">
                                    {reference} <span className="verified-badge"></span>
                                </div>
                                
                                <span>
                                    <CountryWithFlag {...profileData.basic_information} />{' '}
                                    {fetchCountry(living_country).name}
                                </span>
                                <br />
                                <span>
                                    Profile Created by {profile_created_by}{' '}
                                    {moment(created_at).fromNow()}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="freelancer-details">
                        <div className="freelancer-details-list">
                            <ul>
                                <li>
                                    City
                                <strong>
                                        <i className="icon-material-outline-location-on" />
                                        {living_city}
                                    </strong>
                                </li>
                                <li>
                                    Age <strong>{currentAge(dob)}</strong>
                                </li>
                                <li>
                                    Height <strong>{height}</strong>
                                </li>
                                <li>
                                    Religion <strong>{religion}</strong>
                                </li>
                                <li>
                                    Mother Tongue <strong>{mother_tongue}</strong>
                                </li>
                            </ul>
                        </div>
                        {
                            !isAuthenticated ? <button
                                className="button full-width button-sliding-icon ripple-effect"
                                onClick={showLoginModalWindow}
                            >
                                View profile
                                        <i className="icon-material-outline-arrow-right-alt" />
                            </button> :
                            <Link
                                to={`/app/profile/${id}/${reference}`}
                                className="button button-sliding-icon ripple-effect"
                            >
                                View profile
                                <i className="icon-material-outline-arrow-right-alt" />
                            </Link>
                        }
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="section gray padding-top-65 padding-bottom-70 full-width-carousel-fix">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-headline margin-top-0 margin-bottom-25">
                                <h3>Recent Profiles</h3>

                                <Link to="/app/public/search" className="headline-link">
                                    View All Profiles
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className=" freelancers-grid-layout slick-initialized slick-slider">
                                {isComponentLoaded && <Slider {...settings}>{slide(profile)}</Slider>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
