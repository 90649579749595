import React, { useEffect, useState } from 'react';
import Plans from '../components/common/Plans';
import LatestProfiles from '../components/LandingPage/LatestProfiles';
import SimpleBanner from '../components/LandingPage/SimpleBanner';
import getProfileWithBasicDataLanding from '../graphql/queries/getProfileWithBasicDataLanding';
import HowItWorks from '../components/LandingPage/HowItWorks';
import ContactUs from '../components/LandingPage/ContactUs';
import Summary from '../components/LandingPage/Summary';

const LandingPage = () => {
    const [profiles, setProfiles] = useState({
        loading: true,
        data: {
            profile: [],
            profile_count_based_country: [],
            profile_count_based_gender: []
        }
    });

    useEffect(() => {
        getProfileWithBasicDataLanding().then(profileResp => {
            setProfiles(profileResp)
        })
    }, [])

    return (
        <div>
            <SimpleBanner {...profiles}/>
            <LatestProfiles {...profiles} />
            <HowItWorks />
            <Summary {...profiles} />
            <Plans />
            <ContactUs/>
        </div>
    );
};

export default LandingPage;
