import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import TokenClient from '../../client/TokenClient';

const query = gql`
query getProfilesWithBasicData {
  profile(where: { profile_status: { _eq: VERIFIED }}, limit: 5) {
        id
        user_id
        reference
        avatar
        profile_photo
        created_at
        basic_information {
            profile_created_by
            first_name
            last_name
            dob
            gender
            religion
            chevvai_dosham
            marital_status
            caste
            living_city
            living_state
            living_country
            nationality
            residence_status
            country_of_birth
            mother_tongue
            primary_language
        }
        lifestyle_and_appearance {
            height
        }
    }
    profile_count_based_country {
        count
        living_country
    }
    profile_count_based_gender {
        count
        gender
    }
}
`

export default async () => {
    const { db_access_token } = await TokenClient();
    const client = createApolloClient(db_access_token)
    return client
        .query({ query, variables: {} })
        .then(data => {
            return data
        })
        .catch(error => {
            console.log('error', error)
        })
}
