import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import getPlan from '../../graphql/queries/getPlan'
import Loading from './LoadingOverlay'

const Plans = () => {
    const [loading, setLoading] = useState(true)
    const [plans, setPlans] = useState([])

    const getPalaHandler = async () => {
        const { data, loading } = await getPlan()
        if (!loading) {
            setPlans(data.plan.sort((a, b) => a.order - b.order ));
        }
        setLoading(loading)
    }

    useEffect(() => {
        getPalaHandler()
    }, [])

    if (loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <article id="plans">
            <div className="section padding-top-60 padding-bottom-75">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-headline centered margin-top-0 margin-bottom-35">
                                <h3>Membership Plans</h3>
                            </div>
                        </div>
                        <div className="col-xl-12 margin-top-30">
                            <div className="pricing-plans-container">
                                {plans.map((plan, index) => {
                                    return (
                                        <div kay={index} className="pricing-plan recommended">
                                            {plan.is_recommended && (
                                                <div className="recommended-badge">Recommended</div>
                                            )}
                                            <h3>{plan.name}</h3>
                                            <p className="margin-top-10">{plan.description}</p>
                                            <p className="margin-top-10"></p>
                                            <div className="pricing-plan-label billed-monthly-label">
                                                <strong>{plan.price_description}</strong>
                                            </div>
                                            <div className="pricing-plan-features">
                                                <strong>{plan.name} Plan</strong>
                                                <ul className="list-2 margin-left-25">
                                                    {plan.capabilities.map((data, planIndex) => (
                                                        <li key={planIndex}>{data.description}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <Link
                                                to="/app/login"
                                                className="button full-width margin-top-20"
                                            >
                                                {plan.button_label}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default Plans
