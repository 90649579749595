import createApolloClient from '../../client/apolloClient'
import gql from 'graphql-tag'
import TokenClient from '../../client/TokenClient';

const query = gql`
    query MyQuery {
        plan {
            id
            order
            name
            plan_price
            value
            description
            capabilities
            price_description
            is_recommended
            button_label
            daily_contacts
            monthly_contacts
        }
    }
`;

export default async () => {
    const { db_access_token } = await TokenClient();
    const client = createApolloClient(db_access_token)
    return client
        .query({ query, variables: { } })
        .then(data => {
            return data
        })
        .catch(error => {
            console.log('error', error)
        })
}