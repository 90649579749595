import React from 'react'
import { Link } from "@reach/router"
import homeBackground from '../../assets/images/home-background-3.jpg'
import useAuth from '../../hooks/useAuth'

export default () => {
    const { isAuthenticated } = useAuth();
    return (
        <div
            className="photo-section dark-overlay"
            data-background-image=""
            style={{ backgroundImage: `url("${homeBackground}")` }}
        >
            <div className="text-content white-font">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner-headline">
                            <h3>
                                <strong className="xl"> The Matrimonial Service for Sri Lankans</strong>
                                <br />
                                <span>Find your perfect match</span>
                            </h3>
                        </div>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                            <Link
                                to="/app/signup"
                                className="button button-sliding-icon ripple-effect big margin-top-20"
                                style={{ width: '154.875px' }}
                            >
                                Get Started
                                <i className="icon-material-outline-arrow-right-alt" />
                            </Link>
                            <Link
                                to={isAuthenticated ? '/app/search' : '/app/public/search'}
                                className="button button-sliding-icon ripple-effect big margin-top-20 margin-left-20"
                                style={{ width: '154.875px' }}
                            >
                                Search
                                <i className="icon-material-outline-arrow-right-alt" />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <ul className="intro-stats margin-top-45 hide-under-992px">
                            {/* <li>
                                <strong className="counter">1,586</strong>
                                <span>Male</span>
                            </li>
                            <li>
                                <strong className="counter">3,543</strong>
                                <span>Female</span>
                            </li>
                            <li>
                                <strong className="counter">1,232</strong>
                                <span>Freelancers</span>
                            </li> */}
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}