import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../Layout/MainLayout'
import LandingPage from '../containers/LandingPage';
import Meta from '../components/common/Meta'


const index = () => {
    return (
        <StaticQuery
            query={graphql`
            query SiteTitleQuery {
                site {
                siteMetadata {
                    title
                        description
                        keyWords
                    }
                }
            }
        `}
            render={({site: { siteMetadata: { title, description, keyWords }}}) => {
                return(
                    <>
                        <Meta
                            title={title}
                            description={description}
                            keywords={keyWords}
                        />
                        <Layout>
                            <LandingPage/>
                        </Layout>
                    </>
                )
            }}
        />
    )
};

export default index;