import React, { Fragment } from 'react'

export default ({ data: { profile_count_based_gender = [] }}) => {
    return (
        <Fragment>
            <div className="section-headline centered margin-top-0 margin-bottom-35">
                <h3>Summary</h3>
            </div>
            <div className="section padding-top-70 padding-bottom-75">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="counters-container">
                                {/* <div className="single-counter">
                                <i className="icon-line-awesome-suitcase" />
                                <div className="counter-inner">
                                    <h3><span className="counter">1,586</span></h3>
                                    <span className="counter-title">Jobs Posted</span>
                                </div>
                            </div>
                            <div className="single-counter">
                                <i className="icon-line-awesome-legal" />
                                <div className="counter-inner">
                                    <h3><span className="counter">3,543</span></h3>
                                    <span className="counter-title">Tasks Posted</span>
                                </div>
                            </div> */}

                                {profile_count_based_gender.map((profile, index) => {
                                    return (
                                        <div key={index} className="single-counter">
                                            <i className="icon-line-awesome-user" />
                                            <div className="counter-inner">
                                                <h3>
                                                    <span className="counter">{profile.count}</span>
                                                </h3>
                                                <span className="counter-title" style={{ textTransform: 'capitalize'}}>
                                                    {profile.gender} Active Profiles
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="single-counter">
                                    <i className="icon-line-awesome-trophy" />
                                    <div className="counter-inner">
                                        <h3>
                                            <span className="counter">99</span>%
                                        </h3>
                                        <span className="counter-title">Satisfaction Rate</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};