import React from 'react';

const HowItWorks = () => {
    return (
        <div className="section padding-top-65 padding-bottom-65">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section-headline centered margin-top-0 margin-bottom-5">
                            <h3>How It Works?</h3>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="icon-box with-line">
                            <div className="icon-box-circle">
                                <div className="icon-box-circle-inner">
                                    <i className="icon-line-awesome-lock" />
                                    <div className="icon-box-check"><i className="icon-material-outline-check" /></div>
                                </div>
                            </div>
                            <h3>Create an Account</h3>
                            <p>In order to create your unlimited profiles, one account is all you need. One free account gets you into everything. </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="icon-box with-line">
                            <div className="icon-box-circle">
                                <div className="icon-box-circle-inner">
                                    <i className="icon-line-awesome-legal" />
                                    <div className="icon-box-check"><i className="icon-material-outline-check" /></div>
                                </div>
                            </div>
                            <h3>Create Your Profile</h3>
                            <p>Create your profile or for someone</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="icon-box">
                            <div className="icon-box-circle">
                                <div className="icon-box-circle-inner">
                                    <i className=" icon-line-awesome-trophy" />
                                    <div className="icon-box-check"><i className="icon-material-outline-check" /></div>
                                </div>
                            </div>
                            <h3>Send Interest, Short list </h3>
                            <p>If you are interested in a profile make shortlist, send interest or get the contact number immediately</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;